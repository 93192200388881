@media screen and (max-device-width: 480px) {
  #header
  #features
  #about,
  #services,
  #testimonials,
  #contact,
  #footer {
    width: 100%;
  }
}
/* App.css */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #d1e3ff;
  color: #3674c1;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  opacity: 0; /* Initially hide the button */
  transition: opacity 0.3s ease; /* Add a transition effect for opacity */
}

.back-to-top.visible {
  opacity: 1; /* Make the button visible when the 'visible' class is added */
}

::-webkit-scrollbar {
  display: none;
}